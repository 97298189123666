.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: black;
}

.About-header {
  color: black;
}

.About-blob {
  color: black;
}

.App-link {
  color: #61dafb;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
